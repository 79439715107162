module.exports = {
  DOWNLOAD_URL: 'https://almexdownload.com/?id=',
  ARTICLE_STATUS: {
    ARCHIVED: 'ARCHIVED',
    RECENT: 'RECENT',
  },
  BRANDS: {
    ALMEX_IN_A_BOX: 'ALMEX_IN_A_BOX',
    ALMEX_INSTITUTE: 'ALMEX_INSTITUTE',
    BAT: 'BAT',
    CMI: 'CMI',
    EMSYS: 'EMSYS',
    FUSION: 'FUSION',
    GLOBAL_SERVICES: 'GLOBAL_SERVICES',
    LIGHTWEIGHT: 'LIGHTWEIGHT',
    VOTECH: 'VOTECH',
  },
  CONTACT_TYPES: {
    EXPERT: 'EXPERT',
    OFFICE: 'OFFICE',
  },
  // used for Event locations
  CONTINENTS: {
    GLOBAL: 'GLOBAL',
    AFRICA: 'AFRICA',
    ASIA: 'ASIA',
    AUSTRALIA: 'AUSTRALIA',
    EUROPE: 'EUROPE',
    NORTH_AMERICA: 'NORTH_AMERICA',
    SOUTH_AMERICA: 'SOUTH_AMERICA',
  },
  DOWNLOAD_TYPES: {
    DATA_SHEETS: 'DATA_SHEETS',
    MANUALS: 'MANUALS',
  },
  EQUIPMENT_STATUS: {
    NEW: 'NEW',
    USED: 'USED',
    HOLD: 'HOLD',
    SOLD: 'SOLD',
  },
  FORM_TYPES: {
    CONTACT: 'CONTACT',
    INSTITUTE: 'INSTITUTE',
  },
  LANDING_TYPES: {
    BRAND: 'BRAND',
    INDUSTRY: 'INDUSTRY',
    PRODUCT: 'PRODUCT',
  },
  LANGUAGE_SLUGS: {
    DE: 'de',
    EN: 'en',
    ES: 'es',
    ES_CL: 'es',
    ES_PE: 'es',
    FR: 'fr',
    NL: 'nl',
    PL: 'pl',
    PT: 'pt',
    RU: 'ru',
    ZH: 'zh',
  },
  LANGUAGES: {
    DE: 'DE',
    EN: 'EN',
    ES: 'ES',
    ES_CL: 'ES_CL',
    ES_PE: 'ES_PE',
    FR: 'FR',
    NL: 'NL',
    PL: 'PL',
    PT: 'PT',
    RU: 'RU',
    ZH: 'ZH',
  },
  OFFICES: {
    API: 'INDONESIA',
    AUS: 'AUSTRALIA',
    BRA: 'BRASIL',
    CHI: 'CHILE',
    CHN: 'CHINA',
    EUR: 'EUROPE',
    GER: 'GERMANY',
    IND: 'INDIA',
    USA: 'UNITED_STATES',
    CAN: 'CANADA',
    AFR: 'AFRICA',
    MEX: 'MEXICO',
    PER: 'PERU',
    FON: 'FONMAR',
  },
  CONFIGURATIONS: {
    AMP_15: 'AMP_15',
    AMP_30: 'AMP_30',
    SINGLE_PHASE: 'SINGLE_PHASE',
    THREE_PHASE: 'THREE_PHASE',
    PSI_30: 'PSI_30',
    PSI_40: 'PSI_40',
    PSI_45: 'PSI_45',
    PSI_60: 'PSI_60',
    PSI_75: 'PSI_75',
    PSI_100: 'PSI_100',
    PSI_150: 'PSI_150',
    PSI_175: 'PSI_175',
    PSI_200: 'PSI_200',
    RECTANGULAR: 'RECTANGULAR',
    BIAS_17: 'BIAS_17',
    BIAS_22: 'BIAS_22',
  },
  ADD_ONS: {
    CANTILEVER: 'CANTILEVER',
    CLAMPS: 'CLAMPS',
    DATA_LOGGING: 'DATA_LOGGING',
    HANDLES: 'HANDLES',
    IN_FRAME: 'IN_FRAME',
    MULTI_PLATEN: 'MULTI_PLATEN',
    PUMP_RECEPTACLE: 'PUMP_RECEPTACLE',
    REMOTE_CONTROL: 'REMOTE_CONTROL',
    SIDE_BY_SIDE: 'SIDE_BY_SIDE',
    SIDE_BY_SIDE_RECT: 'SIDE_BY_SIDE_RECT',
    SIDE_BY_SIDE_RHOM: 'SIDE_BY_SIDE_RHOM',
    TEMP_CONTROL: 'TEMP_CONTROL',
    TIMER_ALARM: 'TIMER_ALARM',
    WATER_COOL_AIR_PURGE: 'WATER_COOL_AIR_PURGE',
  },
  OPTIONS: {
    BIAS_17: 'BIAS_17',
    BIAS_22: 'BIAS_22',
    CANTILEVER: 'CANTILEVER',
    CLAMPS: 'CLAMPS',
    DATA_LOGGING: 'DATA_LOGGING',
    HANDLES: 'HANDLES',
    IN_FRAME: 'IN_FRAME',
    MULTI_PLATEN: 'MULTI_PLATEN',
    AMP_15: 'AMP_15',
    AMP_30: 'AMP_30',
    PSI_30: 'PSI_30',
    PSI_40: 'PSI_40',
    PSI_45: 'PSI_45',
    PSI_60: 'PSI_60',
    PSI_75: 'PSI_75',
    PSI_100: 'PSI_100',
    PSI_150: 'PSI_150',
    PSI_175: 'PSI_175',
    PSI_200: 'PSI_200',
    RECTANGULAR: 'RECTANGULAR',
    REMOTE_CONTROL: 'REMOTE_CONTROL',
    SIDE_BY_SIDE: 'SIDE_BY_SIDE',
    SIDE_BY_SIDE_RECT: 'SIDE_BY_SIDE_RECT',
    SIDE_BY_SIDE_RHOM: 'SIDE_BY_SIDE_RHOM',
    TEMP_CONTROL: 'TEMP_CONTROL',
    TIMER_ALARM: 'TIMER_ALARM',
    WATER_COOL_AIR_PURGE: 'WATER_COOL_AIR_PURGE',
  },
  PAGE_TYPES: {
    ABOUT: 'ABOUT',
    CAREERS: 'CAREERS',
    CONTACT: 'CONTACT',
    EVENTS: 'EVENTS',
    HISTORY: 'HISTORY',
    HOMEPAGE: 'HOMEPAGE',
    HOPE: 'HOPE',
    INSTITUTE: 'INSTITUTE',
    LANDING: 'LANDING',
    LEADERSHIP: 'LEADERSHIP',
    NEWS: 'NEWS',
    PARTS: 'PARTS',
    PRODUCT: 'PRODUCT',
    PROMO: 'PROMO',
    REPAIR: 'REPAIR',
    RESOURCES: 'RESOURCES',
    SERVICES: 'SERVICES',
    SIMPLE: 'SIMPLE',
    USED: 'USED',
  },
  REGION_SLUGS: {
    ASIA_PACIFIC: 'asiapacific',
    AUSTRALIA: 'australia',
    BRAZIL: 'brasil',
    CHILE: 'chile',
    CHINA: 'china',
    EUROPE: 'europe',
    INDIA: 'india',
    INDONESIA: 'indonesia',
    MEXICO: 'mexico',
    NORTH_AMERICA: 'northamerica',
    PERU: 'peru',
    SOUTH_AFRICA: 'southafrica',
  },
  REGIONS: {
    ASIA_PACIFIC: 'ASIA_PACIFIC',
    AUSTRALIA: 'AUSTRALIA',
    BRAZIL: 'BRAZIL',
    CHILE: 'CHILE',
    EUROPE: 'EUROPE',
    INDIA: 'INDIA',
    INDONESIA: 'INDONESIA',
    MEXICO: 'MEXICO',
    NORTH_AMERICA: 'NORTH_AMERICA',
    PERU: 'peru',
    SOUTH_AFRICA: 'SOUTH_AFRICA',
  },
  RESOURCE_TYPES: {
    BROCHURE: 'BROCHURE',
    CATALOG: 'CATALOG',
    CASE_STUDY: 'CASE_STUDY',
    OPERATING_MANUAL: 'OPERATING_MANUAL',
    ORDER_FORM: 'ORDER_FORM',
    PRESS_RELEASE: 'PRESS_RELEASE',
    PRODUCT_SHEET: 'PRODUCT_SHEET',
    PROMO_VIDEO: 'PROMO_VIDEO',
    SAFETY_ALERT_CERTIFICATION: 'SAFETY_ALERT_CERTIFICATION',
    SAFETY_DATA_SHEET: 'SAFETY_DATA_SHEET',
    TECHNICAL_PAPER: 'TECHNICAL_PAPER',
    TEST_RESULT: 'TEST_RESULT',
    TRAINING_MANUAL: 'TRAINING_MANUAL',
    TRAINING_VIDEO: 'TRAINING_VIDEO',
  },
  SOURCE_TYPE_NAMES: {
    ABOUT: 'GraphCMS_AboutSource',
    CAREERS: 'GraphCMS_CareersSource',
    CONTACT: 'GraphCMS_ContactSource',
    EVENTS: 'GraphCMS_EventsSource',
    HISTORY: 'GraphCMS_HistorySource',
    HOMEPAGE: 'GraphCMS_HomepageSource',
    HOPE: 'GraphCMS_HopeSource',
    INSTITUTE: 'GraphCMS_InstituteSource',
    LANDING: 'GraphCMS_LandingSource',
    LEADERSHIP: 'GraphCMS_LeadershipSource',
    NEWS: 'GraphCMS_NewsSource',
    PARTS: 'GraphCMS_PartsSource',
    PRODUCT: 'GraphCMS_ProductSource',
    PROMO: 'GraphCMS_PromoSource',
    REPAIR: 'GraphCMS_RepairSource',
    RESOURCES: 'GraphCMS_ResourcesSource',
    SERVICES: 'GraphCMS_ServicesSource',
    SIMPLE: 'GraphCMS_SimpleContentSource',
    USED: 'GraphCMS_UsedEquipmentSource',
  },
  SOURCE_TYPE_SIMPLE_NAMES: {
    ABOUT: 'AboutSource',
    CAREERS: 'CareersSource',
    CONTACT: 'ContactSource',
    EVENTS: 'EventsSource',
    HISTORY: 'HistorySource',
    HOMEPAGE: 'HomepageSource',
    HOPE: 'HopeSource',
    INSTITUTE: 'InstituteSource',
    LANDING: 'LandingSource',
    LEADERSHIP: 'LeadershipSource',
    NEWS: 'NewsSource',
    PARTS: 'PartsSource',
    PRODUCT: 'ProductSource',
    PROMO: 'PromoSource',
    REPAIR: 'RepairSource',
    RESOURCES: 'ResourcesSource',
    SERVICES: 'ServicesSource',
    SIMPLE: 'SimpleContentSource',
    USED: 'UsedEquipmentSource',
  },
  SOURCE_TYPES: {
    ABOUT: 'aboutSource',
    CAREERS: 'careersSource',
    CONTACT: 'contactSource',
    EVENTS: 'eventsSource',
    HISTORY: 'historySource',
    HOMEPAGE: 'homepageSource',
    HOPE: 'hopeSource',
    INSTITUTE: 'instituteSource',
    LANDING: 'landingSource',
    LEADERSHIP: 'leadershipSource',
    NEWS: 'newsSource',
    PARTS: 'partsSource',
    PRODUCT: 'productSource',
    PROMO: 'promoSource',
    REPAIR: 'repairSource',
    RESOURCES: 'resourcesSource',
    SERVICES: 'servicesSource',
    SIMPLE: 'simpleContentSource',
    USED: 'usedEquipmentSource',
  },
  THEMES: {
    HEAVYWEIGHT: 'HEAVYWEIGHT',
    LIGHTWEIGHT: 'LIGHTWEIGHT',
    INDUSTRIAL: 'INDUSTRIAL',
    FUSION_COLD: 'FUSION_COLD',
    FUSION_HOT: 'FUSION_HOT',
    FUSION_SPICY: 'FUSION_SPICY',
  },
};
